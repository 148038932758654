import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchEntry, fetchEntries } from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

const Loading = () => (
  <div className="flex items-center justify-center min-h-screen bg-[#202020] font-inter font-normal">
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    <p className="text-gray-700">Loading...</p>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="flex items-center justify-center min-h-screen bg-[#202020] font-inter font-normal">
    <div className="text-red-500 text-lg">
      <p>Error: {message}</p>
      <Link to="/" className="text-blue-500 hover:underline">Go back to the portfolio</Link>
    </div>
  </div>
);

const Artwork = () => {
  const { id } = useParams();
  const [artwork, setArtwork] = useState(null);
  const [embedHtml, setEmbedHtml] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextArtworkSlug, setNextArtworkSlug] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const images = artwork?.fields.images || [];

  useEffect(() => {
    const getArtwork = async () => {
      try {
        const fetchedArtwork = await fetchEntry(id);
        setArtwork(fetchedArtwork);

        const allArtworks = await fetchEntries();
        const currentArtworkIndex = allArtworks.findIndex(art => art.fields.slug === id);
        const nextArtworkIndex = (currentArtworkIndex + 1) % allArtworks.length;
        setNextArtworkSlug(allArtworks[nextArtworkIndex].fields.slug);

        if (fetchedArtwork.fields.sketchfabID) {
          try {
            const response = await fetch(`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${fetchedArtwork.fields.sketchfabID}`);
            const data = await response.json();
            const updatedHtml = data.html.replace('<iframe', '<iframe class="w-full rounded-lg shadow-lg" style="aspect-ratio: 16 / 9;"');
            setEmbedHtml(updatedHtml);
          } catch (sketchfabError) {
            console.error('Error fetching Sketchfab data:', sketchfabError);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getArtwork();
  }, [id]);

  const handleNextImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const handlePrevImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images.length]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        handleNextImage();
      } else if (e.key === 'ArrowLeft') {
        handlePrevImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNextImage, handlePrevImage]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!artwork) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 md:px-8 font-inter font-normal">
        <div className="bg-white rounded shadow-md p-4 md:p-8 w-full max-w-2xl text-center">
          <h1 className="text-2xl md:text-3xl mb-4 text-black">Artwork not found</h1>
          <Link to="/" className="text-blue-500 hover:underline">Go back to the portfolio</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#131313] py-8 font-inter font-normal">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex justify-between items-center mb-6">
          <Link to="/" className="bg-gray-200 hover:bg-gray-300 text-black text-sm font-semibold px-4 py-2 rounded-full shadow-sm">
            ← Back to Portfolio
          </Link>
          {nextArtworkSlug && (
            <Link to={`/artworks/${nextArtworkSlug}`} className="bg-gray-200 hover:bg-gray-300 text-black text-sm font-semibold px-4 py-2 rounded-full shadow-sm">
              Next Artwork →
            </Link>
          )}
        </div>

        <div className="bg-[#202020] rounded-lg shadow-lg p-6 md:p-8">
          <h1 className="text-3xl font-bold mb-6 text-white">{artwork.fields.title}</h1>

          {/* Image viewer */}
          <div className="flex items-center justify-center mb-6 relative">
            {/* Left arrow icon - hide if only one image */}
            {images.length > 1 && (
              <button
                className="absolute left-0 text-white text-3xl p-2 bg-gray-700 rounded-full hover:bg-gray-600"
                onClick={handlePrevImage}
              >
                <BiChevronLeft size={40} />
              </button>
            )}

            {/* Main image display */}
            {images[selectedImageIndex] && (
              <div className="w-full md:w-7/12 lg:w-6/12 xl:w-5/12 overflow-hidden rounded-lg shadow-lg">
                <img
                  className="object-cover w-full h-full"
                  src={images[selectedImageIndex].fields.file.url}
                  alt={artwork.fields.title}
                />
              </div>
            )}

            {/* Right arrow icon - hide if only one image */}
            {images.length > 1 && (
              <button
                className="absolute right-0 text-white text-3xl p-2 bg-gray-700 rounded-full hover:bg-gray-600"
                onClick={handleNextImage}
              >
                <BiChevronRight size={40} />
              </button>
            )}
          </div>

          {/* Thumbnail previews - hide if only one image */}
          {images.length > 1 && (
            <div className="flex gap-4 overflow-x-auto mb-6">
              {images.map((image, index) => (
                <img
                  key={index}
                  className={`w-20 h-20 object-cover rounded-lg cursor-pointer ${selectedImageIndex === index ? 'border-4 border-white' : ''}`}
                  src={image.fields.file.url}
                  alt={artwork.fields.title}
                  onClick={() => setSelectedImageIndex(index)}
                />
              ))}
            </div>
          )}

          {/* Description and Sketchfab embed in flexbox */}
          <div className="flex flex-col md:flex-row justify-between items-start text-white text-lg leading-relaxed bg-[#0B0B0B] rounded-lg shadow p-6 mb-6">
            {/* Left side: Description */}
            <div className="md:w-1/2 pr-4">
              {documentToReactComponents(artwork.fields.description)}
            </div>

            {/* Right side: Sketchfab embed */}
            {embedHtml && (
              <div className="md:w-1/2 mt-4 md:mt-0 flex justify-center">
                <div className="sketchfab-embed-wrapper w-full">
                  <div dangerouslySetInnerHTML={{ __html: embedHtml }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artwork;
