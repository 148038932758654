import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchEntries } from '../contentfulClient'; // Ensure this path is correct

const Portfolio = () => {
  const [artworks, setArtworks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getArtworks = async () => {
      try {
        const allArtworks = await fetchEntries();
        setArtworks(allArtworks);
      } catch (err) {
        console.error('Error fetching artworks:', err);
        setError('Failed to load artworks');
      }
    };

    getArtworks();
  }, []);

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="relative flex items-start justify-center min-h-screen bg-[#0B0B0B] p-4 sm:p-6 lg:p-8">
      {/* Line above the container */}

      {/* Main container */}
      <div className="bg-[#131313] shadow p-6 sm:p-8 w-full max-w-[1200px] z-10  relative">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {artworks.length > 0 ? (
            artworks.map((artwork, index) => (
              <Link to={`/artworks/${artwork.fields.slug}`} key={index} className="relative block">
                <div className="relative w-full h-0 pb-[133%] shadow overflow-hidden">
                  <img 
                    className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 transform hover:scale-105"
                    src={artwork.fields.image.fields.file.url}
                    alt={artwork.fields.title}
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 text-center">
                    <p className="text-white text-lg font-inter">{artwork.fields.title}</p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p className="text-center text-gray-400">No artworks available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;