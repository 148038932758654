import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaYoutube } from 'react-icons/fa';

const Header = () => (
  <header className="bg-[#0B0B0B] p-4 relative">
    <div className="max-w-6xl mx-auto flex flex-col sm:flex-row items-center">
      {/* Profile and Description Section */}
      <div className="flex flex-col sm:flex-row items-center sm:items-start justify-center mb-4 sm:mb-0 flex-grow">
        {/* Profile Picture */}
        <img 
          className="w-[90px] h-[90px] sm:w-[118px] sm:h-[118px] shadow mb-4 sm:mb-0 sm:mr-4" 
          src="https://images.ctfassets.net/srfwb65ha3ql/5g4ASAU2f7EDOlwR8baH5y/6904f787c991f2284253f0dc2a178bd7/pic.jpg" 
          alt="Gianluca Gatto Profile" 
          aria-hidden="true" 
        />
        {/* Name and Description */}
        <div className="flex flex-col justify-between h-[90px] sm:h-[118px]">
          <h1 className="text-white text-2xl sm:text-3xl font-bold font-inter leading-9 text-center sm:text-left">
            <Link to="/" className="hover:text-gray-400 transition-colors duration-300">
              GIANLUCA GATTO
            </Link>
          </h1>
          <p className="text-white text-[13px] sm:text-sm font-medium font-inter max-w-xs leading-tight text-center sm:text-left">
            I'm Gianluca, a 3D artist using Blender to craft bold, immersive digital worlds. Blending creativity with precision, I push the boundaries of what's possible in digital art.
          </p>
        </div>
      </div>

      {/* Navigation Section */}
      <nav className="flex items-center sm:ml-4 space-x-4">
        {/* Contact Button */}
        <Link
          to="/contact"
          aria-label="Contact Gianluca Gatto"
          className="relative flex items-center justify-center w-auto min-w-[50px] h-[50px] text-white rounded-full overflow-hidden group"
        >
          <span className="absolute inset-0 bg-gradient-to-r from-gray-700 to-blue-900 rounded-full transition-transform transform group-hover:scale-105 duration-300 ease-in-out"></span>
          <span className="absolute inset-0 w-full h-full border-2 border-transparent rounded-full group-hover:border-white transition-all duration-300"></span>
          <FaEnvelope className="relative z-10 text-xl transition-transform duration-300 group-hover:scale-110" role="img" aria-label="Email Icon" />
          <span className="absolute left-full ml-2 opacity-0 scale-75 text-sm font-medium transition-all duration-500 transform group-hover:left-16 group-hover:opacity-100 group-hover:scale-100">
            Contact Me
          </span>
        </Link>

        {/* YouTube Button */}
        <a
          href="https://www.youtube.com/@gianlucagatto1" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="YouTube"
          className="relative flex items-center justify-center w-auto min-w-[50px] h-[50px] text-white bg-red-600 hover:bg-red-500 rounded-full shadow-lg transition-all duration-300 ease-in-out overflow-hidden"
        >
          <FaYoutube className="text-xl transition-transform duration-300 group-hover:scale-110" role="img" aria-label="YouTube Icon" />
        </a>
      </nav>
    </div>
  </header>
);

export default Header;
